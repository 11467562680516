const employmentOptions =
{
    seniorityLevels: [
        "Student/Trainee",
        "Junior",
        "Middle",
        "Senior",
        "Lead",
        "Manager",
        "Director"
    ],
    platforms: [
        "PC",
        "Console",
        "Mobile",
        "VR",
        "AR",
        "Web",
        "Social",
        "Not applicable"
    ],
    employmentOptions : [
        "Full-time",
        "Part-time",
        "Freelance"
    ],
    workPreferences : [
        "Remote",
        "Onsite",
        "Hybrid"
    ],
    artAndAnimationStyles: [
        "Anime & Manga",
        "Fantasy",
        "Hard Surface",
        "Horror",
        "Pixel and Voxel Art",
        "Realistic",
        "Sci-Fi",
        "Stylized"
    ],
    gameEngines: [
        "Unity",
        "Unreal Engine (UE)",
        "Amazon Lumberyard",
        "ARkit",
        "Blender",
        "Cocos2d",
        "Consolution",
        "Construct",
        "Corona SDK",
        "CryENGINE",
        "Frostbite",
        "GameMaker: Studio",
        "GameSalad",
        "Godot",
        "Havok",
        "Havok Vision Engine",
        "HeroEngine",
        "ID Tech",
        "Infinity Engine",
        "Kivy",
        "libGDX",
        "LÖVE",
        "Phaser",
        "PlayCanvas",
        "RAGE Engine",
        "RPG Maker VX Ace",
        "The Dark Engine",
        "Turbulenz"
    ], gameGenres: [
        "Action",
        "Adventure",
        "Arcade",
        "AR/Location Based",
        "Puzzle",
        "Life-style",
        "Simulation",
        "Hyper-casual",
        "Casino",
        "Shooter",
        "RPG",
        "Racing",
        "Card Games",
        "Strategy",
        "Sports",
        "Driving",
        "Match 3",
        "Music", 
        "Board Games",
        "Casual",
        "Fighting", 
        "Platformer", 
        "Educational",
        "MMO", 
        "Kids Games",
        "Word Games"
    ],
    skills: [  
        ".NET",
        "2D",
        "2D Animation",
        "2D Art",
        "2D Game Art",
        "2D Modeling",
        "2D Render",
        "3D",
        "3D Animation",
        "3D Blender",
        "3D CHARACTER ARTIST",
        "3D Coat",
        "3D Engine",
        "3D Game Art",
        "3D Max",
        "3D Modeling",
        "3D Printing",
        "3D Studio Max",
        "3D Texturing",
        "3D Visualisation",
        "3DCoat",
        "3Ds Max",
        "3DsMax",
        "3UTool",
        "AB testing",
        "Ableton",
        "Ableton Live",
        "Academic Drawing",
        "Acting, Creative, Character",
        "ActionScript 3",
        "ActiveCampaign",
        "Ad monetization",
        "Ad Tech",
        "Adjust",
        "AD-LDS",
        "Adobe After Effects",
        "Adobe Animate",
        "Adobe Character Animator",
        "Adobe Creative Suite",
        "Adobe Illustrator",
        "Adobe InDesign",
        "Adobe Photoshop",
        "adobe pre",
        "Adobe Premier Pro",
        "Adobe Premiere Pro",
        "adobe Substance Painter",
        "Adobe XD",
        "Adventure Creator for Unity",
        "Advertising",
        "Advertising tech stack design",
        "Affinity Designer",
        "Affinity Photo",
        "After Effects",
        "Agile",
        "AI",
        "Airbrush",
        "Airflow",
        "Algorithms and Data Structures",
        "Analysis",
        "Analytics",
        "Anatomy",
        "Android SDK",
        "Android Studio",
        "Android Testing",
        "Angular",
        "Animate CC",
        "Animatic",
        "Animation",
        "ANIMATION STUDIO",
        "Anka",
        "Ansible",
        "Apache JMeter",
        "Apache Kafka",
        "Apache Maven",
        "Apache Server",
        "Apache Spark",
        "API",
        "Appium",
        "Apple App",
        "Applied Statistics",
        "Appsflyer",
        "AR",
        "AR Core",
        "AR Kit",
        "arch",
        "Architectural Design",
        "arnold",
        "Arnold Renderer",
        "Art",
        "Art Direction",
        "Art pipeline",
        "Art workflows",
        "Articy: Draft 3",
        "Articy:Draft 2",
        "Artificial Intelligence (AI)",
        "Asana",
        "ASE",
        "Aseprite",
        "ASO",
        "Assembly",
        "Assets",
        "Atlassian toolchain IAM",
        "Audacity",
        "Audio Editing",
        "Audio editing/recording",
        "Audio Engineering",
        "Audio Occlusion",
        "Audio Post Production",
        "Audio Prioritization",
        "Audio Recording",
        "Audiokinetic Wwise",
        "Auth",
        "AutoCAD",
        "Autodesk maya",
        "Autodesk Maya, Substance Painter",
        "automation",
        "Automation testing",
        "AutoML",
        "AWS",
        "axure",
        "Azure",
        "babylonJS",
        "Backend Development",
        "Baking",
        "baking maps",
        "Balancing",
        "Balsamiq",
        "Bananatag",
        "Bash",
        "Behaviour Trees",
        "Bevy",
        "BI Tools",
        "Big Data",
        "BigQuery",
        "Blender",
        "Blender 3D",
        "Bloomberg",
        "Blueprints",
        "Branching",
        "Broadcasting",
        "Bug Reporting",
        "Bug Tracking",
        "Bugzilla",
        "Business Analysis",
        "Business Development",
        "Business Process Improvement",
        "Business Strategy",
        "C",
        "C#",
        "C#, Unity",
        "C++",
        "C++(UE)",
        "camera walkthrough",
        "Campaigns Management",
        "Campaigns Optimization",
        "Canva Pro",
        "CARTOON ANIMATION",
        "CARTOONS",
        "CD",
        "CelAction",
        "Celtx",
        "CentOS",
        "CG",
        "Change Control",
        "Change Management",
        "Character Animation",
        "Character Art",
        "Character Design",
        "Character Designs",
        "Character Rigging",
        "Charles",
        "Check Lists",
        "ChoiceScript",
        "Chrome Dev Tools",
        "CI",
        "CI/CD",
        "Cinema 4D",
        "Cinematic designer",
        "ClickHouse",
        "ClickUp",
        "clippaint",
        "clipstudio",
        "ClipStudioPaint",
        "clo3d",
        "Cloud computing",
        "Cloud engineering",
        "Cloud platforms",
        "Cloud technologies",
        "Cloud-based services",
        "Cluster Analysis",
        "Clusters",
        "CMake",
        "CMS",
        "Cocos",
        "Cocos2D/3D",
        "Cocos2d-x",
        "code reviews",
        "Coding",
        "Cognitive Science",
        "Collaborative Game Design",
        "COLOR CORRECTION",
        "Comic Arts",
        "Commercials",
        "Compositing",
        "Computer Games",
        "Computer Graphics",
        "Computer-Aided Design (CAD)",
        "Concept Art",
        "Concept Development",
        "Confluence",
        "Confluence Game features",
        "Construct 3",
        "Consumer Behaviour",
        "Container services",
        "Content Management System",
        "Continuous Improvement",
        "Cool Edit Pro",
        "Copywriting",
        "CoreData",
        "Corona Render",
        "Corrective and Preventive Action (CAPA)",
        "CPU-Z",
        "Craft",
        "Creative Brief",
        "Creative brief/strategy",
        "Creative concept",
        "Creative Problem Solving",
        "Creative Strategy",
        "Creative Writing",
        "Creature Design",
        "Creature Modeling",
        "CRM",
        "Cross-broswer testing",
        "Cross-browser testing",
        "CrowdIn",
        "CSP",
        "CSS",
        "Cubase",
        "Cucumber",
        "Cucumber BDD",
        "Customer Development",
        "Customer Service",
        "Cycle",
        "Cygwin",
        "Cypress",
        "Dart",
        "Dashboards",
        "Data Analysis",
        "Data Integration",
        "Data Mining",
        "Data Modeling",
        "Data Retrieval",
        "Data Security",
        "Data Visualisation",
        "Data Visualisation,",
        "Data Visualization",
        "Data Warehousing",
        "Database Design",
        "Database Management",
        "Database Systems",
        "Databricks Lakehouse",
        "Data-driven-programming",
        "Davinci Resolve",
        "Daz studio",
        "DBT",
        "Deep Learning",
        "Design",
        "Design Documentation",
        "Design Patterns",
        "Development pipelines",
        "Devkits SDKs",
        "Dialogue systems",
        "Digart",
        "Digital Marketing",
        "Digital Painting",
        "Digital Sculpting",
        "Direct X",
        "Django",
        "Docker",
        "Documentaries",
        "DOTS",
        "Draw.io",
        "Drawing",
        "Dropbox SDK",
        "Dubbing",
        "Economics",
        "Elasticsearch",
        "Elixir",
        "Email Marketing",
        "Emarsys",
        "EmberGen",
        "Emotional Intelligence",
        "Engineering",
        "Entity-Component-Systems (ECS) framework",
        "Environment",
        "Environment Design",
        "EPC",
        "EQS",
        "ETL",
        "Excel",
        "Exploratory Data Analysis",
        "Express JS",
        "F2P Free to Play",
        "Facebook",
        "Facebook ads",
        "Facebook API",
        "Facial Rigging & Animation",
        "Failure Mode and Effects Analysis (FMEA)",
        "Falcon",
        "FastAPI",
        "Fastlane",
        "FeathersJS",
        "Fedora",
        "Field Recording",
        "Figma",
        "Film",
        "Film Production",
        "Final Cut Pro",
        "Final Draft",
        "Financial Analysis",
        "Firebase",
        "Fivetran",
        "FL Studio",
        "Flask",
        "FlowMapp",
        "Flutter",
        "FMOD",
        "Fortran",
        "Framer X",
        "Free to Play (F2P)",
        "Functional Testing",
        "fusion 360",
        "Gaea",
        "game",
        "Game Analytics",
        "Game Art",
        "Game Design",
        "Game Development (GameDev)",
        "Game Events Planning",
        "Game Insights",
        "Game Mechanics",
        "Game Testing",
        "GameBench",
        "Gameface",
        "GameMaker Studio 2",
        "Gameplay",
        "Gameplay effects",
        "Gameplay mechanics",
        "Gant",
        "GCP",
        "gdevelop",
        "Generalist",
        "Gherkin",
        "Git",
        "Github",
        "GLSL",
        "Go",
        "Go/golang",
        "Godot",
        "Golang",
        "Golang or Go/golang",
        "Google ads",
        "Google Analytics",
        "Google BigQuery",
        "Google Cloud Platform",
        "Google Docs",
        "Google Maps & API",
        "Google play",
        "Google Suite",
        "Google Workspace",
        "Google Workspace Admin",
        "GPU",
        "GPU-Z",
        "Gradle",
        "Grafana",
        "Graphic Design",
        "Graphical User Interface (GUI)",
        "Graphical User Interface GUI",
        "Graphics tablets",
        "GraphQL",
        "Growth and Tests",
        "GRPC api",
        "Hadoop",
        "Hansoft",
        "Hash Functions",
        "HDRi Light Studio",
        "HealthKit",
        "Helpshift",
        "Hero",
        "Hibernate",
        "Hive",
        "HLSL",
        "Hootsuite",
        "Houdini",
        "HP LoadRunner",
        "HTML",
        "HTTP",
        "Hybrid Apps",
        "HydraAsync & GCD",
        "HyperCasual Game Development",
        "Hypothesis Testing",
        "IAM",
        "IAP & Subscriptions",
        "IBM SPSS Statistics",
        "Iconic",
        "Illustration",
        "Immersive experiences",
        "Industrial Design",
        "Inform 7",
        "Informatica PowerCenter",
        "Information Architecture",
        "Information Retrieval",
        "Information Visualization",
        "In-game dialogue",
        "Ink",
        "Inkscape",
        "Instagram API",
        "IntelliJ",
        "Interaction Design",
        "Internet Marketing",
        "InVision Studio",
        "iOS Testing",
        "Ironsource",
        "iZotope",
        "Izotope RX",
        "Jaeger",
        "Java",
        "Java EE",
        "JavaScript (JS)",
        "JavaScript Game Development",
        "JavaScript JS",
        "Jenkins",
        "Jenkins Pipelines",
        "JetBrains",
        "JetBrains Rider(IDE)",
        "Jira",
        "jQuery",
        "Json",
        "JUnit",
        "JVM",
        "kafka",
        "Kaiten",
        "Keyshot",
        "kontakt",
        "Kotlin",
        "Krita",
        "Kubernetes",
        "Lambda",
        "Layout Design",
        "LDAP",
        "Leadership",
        "Lean Production",
        "Level Design",
        "Lighting",
        "Linear",
        "Linear Algebra",
        "Linguistic testing",
        "Linguistics",
        "LinkedIn ads",
        "Linux",
        "Live Operations",
        "LMS",
        "Localization",
        "Localization Test",
        "Localization Testing",
        "Logic Pro",
        "Logo Design",
        "Looker",
        "Lottie",
        "LQA",
        "Lua",
        "Lucidchart",
        "machinations",
        "Machine Learning",
        "MagicaVoxel",
        "Management",
        "Manual Testing",
        "Mari",
        "Market Analysis",
        "Marketing",
        "Marketing art",
        "Marketing Management",
        "Marketing Strategy",
        "Marmoset",
        "Marmoset Renderer",
        "Marmoset Toolbag",
        "Marvel",
        "marvellous designer",
        "marvelous",
        "Marvelous Designer",
        "Marvelouse Designer",
        "Master Data Management",
        "Material",
        "Materials",
        "Mathematical and Applied Statistics",
        "MatLab",
        "Maven",
        "Max",
        "Max MSP",
        "Maxscript",
        "Maya",
        "Mechanical Engineering",
        "MEL",
        "Meltwater",
        "MemoQ",
        "Memsource",
        "mentoring",
        "Menu-Driver User Interface",
        "Meta",
        "MetaSounds",
        "Microservices",
        "Microsoft (MS) SQL Server",
        "Microsoft Excel",
        "Microsoft Office",
        "Microsoft Project",
        "Microsoft SQL Server",
        "Microsoft Suite",
        "Milanote",
        "Miro",
        "MMO",
        "Mobile games",
        "Mobile Testing",
        "Mocha",
        "Mockito",
        "Modeling",
        "Modular Design",
        "Monetization",
        "MongoDB",
        "Mongoose",
        "MonoGame",
        "Motion Capture",
        "Motion Capture Acting",
        "Motion Graphics",
        "Moya & AFNetworking",
        "MR",
        "MS Excel",
        "MSI Afterburner",
        "Mudbox",
        "Music Composition",
        "Music Production",
        "Music systems",
        "MVVM",
        "MyBatis",
        "MySQL",
        "Narrative Design",
        "Narrative Stack",
        "Native iOS Development",
        "Negotiations",
        "NestJs",
        "Netsuite",
        "Network Security",
        "Nginx",
        "Node.js",
        "Nomad Sculpt",
        "NoSQL",
        "NoSQL Database",
        "Notifications",
        "Notion",
        "Nuclino",
        "Nuke",
        "NUnit",
        "Oasis360",
        "OAuth",
        "Obejective C",
        "Object-Oriented Design",
        "Olive",
        "Online Advertising",
        "Online Marketing",
        "OOP",
        "Open GL",
        "OpenID Connect",
        "OpenLDAP",
        "Optimal Workshop",
        "Optimization",
        "Origami Studio",
        "PaintTool SAI",
        "Particles",
        "PBR Texturing",
        "PC Testing",
        "Perforce",
        "Perl",
        "Perspective",
        "photogrammetry",
        "Photography",
        "PHP",
        "Pipeline",
        "Piskel",
        "pixaki",
        "Pixel Art",
        "Pixi",
        "Pixi.Js",
        "PixiJs",
        "Pixyz",
        "Player Behavior",
        "Player expereince",
        "Player experience",
        "Player testing",
        "PlayStation Tools",
        "Playtest",
        "PlaytestCloud",
        "Playvox",
        "PLSQL",
        "PMI / PMBOK",
        "PostgreSQL",
        "Postman",
        "Postman API",
        "Power Query",
        "PowerBI",
        "Powershell",
        "Predictive Analytics",
        "Presenting/acting/voice acting",
        "Pretesting Analysis",
        "Primavera P6",
        "prisme",
        "Pro Tools",
        "Procedural modeling",
        "Procreate",
        "Procreate IOS",
        "product animation",
        "Product Development",
        "Product Engineering",
        "Product Management",
        "Product Marketing",
        "Programmatic Expertise",
        "Project Engineering",
        "Project Management",
        "Project Planning",
        "Proofreading",
        "Prop Design",
        "Props Animation",
        "Proto.io",
        "Prototyping",
        "Publishing",
        "Puppeteer",
        "Python",
        "Python Scripting",
        "Qlik Sence",
        "QlikView",
        "Qualitative Methodology",
        "Qualitative research",
        "Quality Assurance (QA)",
        "Quality Auditing",
        "Quality Control",
        "Quality Management",
        "Quality System",
        "Quantitative Methodology",
        "Quest Design",
        "Quixel",
        "R",
        "R Language",
        "React",
        "React Native",
        "React Programming",
        "RealityCapture",
        "Realm",
        "Reaper",
        "Recruitment",
        "Redis",
        "Regression",
        "Regression Analysis",
        "Regression Modeling",
        "Regression Testing",
        "Rendering",
        "Reporting Statistics and Patterns",
        "Requirements Analysis",
        "Requirements Gathering",
        "Research",
        "Resource Planning",
        "REST",
        "Rest Assured",
        "Retopology",
        "Reverse Engineering",
        "Revit",
        "rhinoceros 3D",
        "Rigging",
        "RIZOM UV",
        "RizomUV",
        "Roblox",
        "Root Cause Analysis",
        "Rotoscoping",
        "RPG",
        "RPG Maker",
        "Ruby",
        "Ruby on Rails",
        "Rust",
        "RxCocoa",
        "RxDart",
        "RxSwift",
        "SAML2",
        "SAS Business Intelligence",
        "SASS",
        "Scala",
        "Scaleform",
        "Screenwriting",
        "Script/screen writing",
        "Scripting in general",
        "Scrivener",
        "Scrum",
        "Sculpting",
        "Scuplting",
        "SDK",
        "SDK Jenkins",
        "SDK Management",
        "Search (Engine) Optimization",
        "Selenide",
        "Selenium",
        "Sensor Tower",
        "SEO",
        "SEO Optimization",
        "Servant Leadership",
        "Session Link Pro",
        "Set Design",
        "Shaderlab",
        "Shaders",
        "Shell",
        "Shotgrid",
        "shotgun",
        "Shuriken",
        "Site Audit",
        "Sketch",
        "sketchbook pro",
        "Sketching",
        "Slab",
        "Slack",
        "Slot games",
        "Smali",
        "SmartCAT",
        "Smartsheets",
        "Smoke Testing",
        "Snowflake",
        "SOAP",
        "Social games",
        "Social Media",
        "Software Testing",
        "Sound Design",
        "Sound Forge",
        "SourceTree",
        "SPA",
        "Spark",
        "SpeedTree",
        "Spine",
        "Spine2D",
        "splash art",
        "Spring",
        "Spring Boot",
        "Spring Cloud",
        "Spring Data",
        "Spring Framework",
        "Spring JDBC",
        "Spring Kafka",
        "Sputnik",
        "SQL",
        "SQL Server Analysis Services (SSAS)",
        "SQL Server Integration Services (SSIS)",
        "SQL Server Reporting Services (SSRS)",
        "SQLite",
        "SQS",
        "SSO systems",
        "Stage Design",
        "Statistical Analysis Methods",
        "Statistical Modelling",
        "Statistical Programming",
        "Statistical Tools",
        "Statistics",
        "Steinberg Cubase Pro 12",
        "Storyboarding",
        "Storybuilding",
        "Storytelling",
        "Strategic Planning",
        "Stress Testing",
        "Studio Management",
        "Studio One",
        "stylized",
        "Substance 3D Designer",
        "Substance 3D Painter",
        "Substance 3D Sampler",
        "substance Designer",
        "Substance Painter",
        "Substance Suite",
        "substanse designer",
        "Swagger",
        "Swift",
        "System Testing",
        "Tableau",
        "Tasks",
        "TD",
        "Team Management",
        "Teamcity",
        "Techical Animator",
        "Television",
        "Terraform",
        "Test Cases",
        "Test Documentation",
        "Test Management",
        "Test Plan Development",
        "Test Planning",
        "TestFlight",
        "Testing",
        "Testing Methodologies",
        "TestLink",
        "Testrail",
        "Text Editing",
        "Texture Painting",
        "Texturing",
        "Third-party SDK’s",
        "ThreeJS",
        "Ticket management",
        "tilesetter",
        "Tomcat",
        "ToonBoom Harmony",
        "Traditional Animation",
        "Traditional Art",
        "Traditional Painting",
        "Translation",
        "Treejack",
        "Trello",
        "Troubleshooting and Testing",
        "TV/Video",
        "TVPaint",
        "Twine",
        "Types of Testing",
        "TypeScript",
        "Typography",
        "UI",
        "UI Design",
        "UI sounds",
        "UMG",
        "Unit Testing",
        "Unity",
        "Unity 3D",
        "Unity DOTS",
        "Unreal Blueprints",
        "Unreal Engine UE",
        "User Experience (UX)",
        "User Experience Design (UED)",
        "User Interface",
        "User Interface (UI)",
        "User Interface Design (UI Design)",
        "User research",
        "User testing",
        "User Unterface (UI)",
        "UV Mapping",
        "UX",
        "UX research",
        "UX strategy",
        "UXPin",
        "Various analytics",
        "Vehicle Modeling",
        "Vehicle Moduling",
        "Vendor Management",
        "Verilog",
        "Version Control Systems",
        "Video",
        "Video Editing",
        "Video Post-Production",
        "Video Production",
        "Video production/editing",
        "VirtualBox",
        "Visio",
        "Visio,",
        "Vision",
        "visual",
        "Visual design",
        "Visual Effects (VFX)",
        "Visual Scripting",
        "Visual Storytelling",
        "Visual Studio",
        "Visualization",
        "Visualizing Statistics and Patterns",
        "VisualSitemaps",
        "Voice Acting",
        "Voice User Interface (VUI)",
        "VR",
        "VR Integration",
        "VR Kit",
        "Vray",
        "V-RAY",
        "Vue.js",
        "Vulkan API",
        "Wacom",
        "Watercolor",
        "Waterfall",
        "Weapon Design",
        "Weapon Modeling",
        "Web based services",
        "Web Design",
        "Web development",
        "Web testing",
        "Webflow",
        "WebGL",
        "Webpack",
        "Wildfly",
        "Wireframing",
        "Wordpress",
        "Workday",
        "World Machine",
        "WPF",
        "writ",
        "Writing",
        "Wwise",
        "Xamarin",
        "Xbox Tools",
        "Xcode",
        "Xgen",
        "XMind",
        "XML",
        "XMPP",
        "xNormal",
        "XR",
        "Yarn Spinner",
        "Yield Optimization",
        "Zbrush",
        "Zeebe",
        "Zendesk",
        "Zenject",
        "Zephyr Scale",
        "Zeplin",
        "Zookeeper",
        "Zoology"
    ]
};
module.exports = employmentOptions;
